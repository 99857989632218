import React from 'react'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import { SquareImage, SwitchInput } from 'components'

const DiscoverOverrideDetails = ({
	discoverOverride,
	togglePublished,
}) =>
	!discoverOverride ? (
		<Typography variant="body2">
			No marketing tile.
		</Typography>
	) : (
		<Grid container spacing={1}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Box p={4}>
						<center>
							<Typography variant="body2">
								Image (Sq.)
							</Typography>
							<SquareImage
								src={discoverOverride.image_url}
								variant="rounded"
								size={420}
							/>
						</center>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={8}>
				<List>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Title
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{discoverOverride.title
										? discoverOverride.title
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Subtitle
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{discoverOverride.subtitle
										? discoverOverride.subtitle
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						{/* TODO: Audio */}
						<ListItemText
							primary={
								<Typography variant="body2">
									Audio
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{discoverOverride.audio.id
										? discoverOverride.audio.id
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Grid>
			<ListItem>
				<Box flex="column">
					<ListItemText
						primary={
							<Typography variant="body2">
								Published
							</Typography>
						}
					/>
					<SwitchInput
						component="span"
						value={
							discoverOverride.published === true
								? true
								: false
						}
						label=""
						name="published"
						handleChange={togglePublished}
					/>
				</Box>
			</ListItem>
		</Grid>
	)

export default DiscoverOverrideDetails
