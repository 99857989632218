import React from 'react'
import { useMenu } from 'hooks'
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox,
	withStyles,
} from '@material-ui/core'
import { Image } from 'components'
import {
	MoreHoriz,
	Favorite,
	Person,
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import Rating from '@material-ui/lab/Rating'

const StyledRating = withStyles({
	iconFilled: {
		color: '#ff6d75',
	},
	iconHover: {
		color: '#ff3d47',
	},
})(Rating)

const RatingItem = ({
	rating,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) => handleDeleteClick(rating, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(rating.id)

	const history = useHistory()
	const handleUserClick = (user) =>
		history.push(`/users/${user.id}`)
	const handleAudioClick = (audio) =>
		history.push(`/sessions/${audio.id}`)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(rating)}
						name="rating"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				{rating?.user?.name && (
					<Button
						onClick={() => handleUserClick(rating.user)}
					>
						{rating?.user?.name}
					</Button>
				)}
			</TableCell>
			<TableCell>
				{rating?.audio?.name && (
					<Button
						onClick={() => handleAudioClick(rating.audio)}
					>
						{rating?.audio?.name}
					</Button>
				)}
			</TableCell>
			<TableCell>
				<StyledRating
					icon={<Favorite fontSize="inherit" />}
					name="read-only"
					value={rating.overall_rating}
					readOnly
				/>
			</TableCell>
			<TableCell>
				<StyledRating
					icon={<Favorite fontSize="inherit" />}
					name="read-only"
					value={rating.trainer_rating}
					readOnly
				/>
			</TableCell>
			<TableCell>
				<StyledRating
					icon={<Favorite fontSize="inherit" />}
					name="read-only"
					value={rating.music_rating}
					readOnly
				/>
			</TableCell>
			<TableCell>
				<StyledRating
					icon={<Favorite fontSize="inherit" />}
					name="read-only"
					value={rating.session_rating}
					readOnly
				/>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default RatingItem
