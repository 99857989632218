import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	useTrainers,
	useForm,
	useAlerts,
	useVibes,
} from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
	Grid,
} from '@material-ui/core'
import {
	ImageUploader,
	FileUploader,
	Subheader,
	FullScreenImage,
} from 'components'
import { Check } from '@material-ui/icons'
import TrainerForm from 'containers/trainers/TrainerForm'

const TrainerEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		trainerId,
		isLoading,
		isLoaded,
		trainer,
		handleChange,
		updateTrainer,
		findTrainer,
		uploadFile,
		deleteFile,
	} = useTrainers({ id: id })

	const vibeImageUrlsById =
		trainer.avatar_image_url_by_vibe_id ?? {}

	const { vibes, vibesById, findVibes } = useVibes()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateTrainer(trainer)
		showAlertSuccess('Trainer has been updated')
		history.push('/trainers')
	}

	const handleUploadAvatarImage = async (file) => {
		await uploadFile(file, 'avatar_image_url')
		findTrainer(id)
	}

	const handleUploadAvatarImageByVibe =
		(vibeId) => async (file) => {
			await uploadFile(
				file,
				`avatar_image_url_by_vibe_id[${vibeId}]`
			)
			findTrainer(id)
		}

	const handleUploadFullscreenImage = async (file) => {
		await uploadFile(file, 'fullscreen_image_url')
		findTrainer(id)
	}

	const handleUploadMedia = async (file) => {
		await uploadFile(file, 'media_url')
		findTrainer(id)
	}

	const handleDeleteAvatarImage = async () => {
		await deleteFile('avatar_image_url')
		findTrainer(id)
	}

	const handleDeleteAvatarImageByVibe =
		(vibeId) => async () => {
			await deleteFile(
				`avatar_image_url_by_vibe_id[${vibeId}]`,
				{ vibeId }
			)
			findTrainer(id)
		}

	const handleDeleteFullscreenImage = async () => {
		await deleteFile('fullscreen_image_url')
		findTrainer(id)
	}

	const handleDeleteMedia = async () => {
		await deleteFile('media_url')
		findTrainer(id)
	}

	useEffect(() => {
		findVibes()
		if (id) findTrainer(id)
	}, [])

	return (
		<>
			<Subheader
				title="Edit Trainer"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} lg={4}>
								<Typography variant="body2">
									Fullscreen Image
								</Typography>
								<ImageUploader
									imageUrl={trainer.fullscreen_image_url}
									demoImage={() => (
										<FullScreenImage
											src={trainer.fullscreen_image_url}
											height={200}
										/>
									)}
									handleUpload={handleUploadFullscreenImage}
									handleDelete={handleDeleteFullscreenImage}
								/>
							</Grid>
							<Grid item xs={12} sm={6} lg={4}>
								<Typography variant="body2">
									Avatar Image
								</Typography>
								<ImageUploader
									imageUrl={trainer.avatar_image_url}
									handleUpload={handleUploadAvatarImage}
									handleDelete={handleDeleteAvatarImage}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							{vibes.map((vibe, index) => (
								<Grid
									key={index}
									item
									xs={12}
									sm={6}
									lg={4}
								>
									<Typography variant="body2">
										{vibe.name} Image
									</Typography>
									<ImageUploader
										size={160}
										imageUrl={
											vibeImageUrlsById[vibe.id] ?? ''
										}
										handleUpload={handleUploadAvatarImageByVibe(
											vibe.id
										)}
										handleDelete={handleDeleteAvatarImageByVibe(
											vibe.id
										)}
									/>
								</Grid>
							))}
						</Grid>
						<Box>
							<Typography variant="body2">
								Trainer Audio
							</Typography>
							<center>
								<FileUploader
									fileUrl={trainer.media_url}
									handleUpload={handleUploadMedia}
									handleDelete={handleDeleteMedia}
								/>
							</center>
						</Box>
						<TrainerForm
							trainer={trainer}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default TrainerEdit
