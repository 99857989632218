import {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import api from 'api'

const useTopPlaylists = ({ ...props }) => {
	const [isLoading, setIsLoading] = useState(true)
	const [topPlaylists, setTopPlaylists] = useState([])

	const reload = useCallback(async () => {
		setIsLoading(true)
		const res = await api.get('/playlists/top_playlists')
		setTopPlaylists(res.data ?? [])
		setIsLoading(false)
	}, [])

	const addTopPlaylist = useCallback(async (playlistId) => {
		const res = await api.put(
			`/admin/playlists/${playlistId}`,
			{
				playlist: { is_top_playlist: true },
			}
		)
		reload()
	}, [])

	const addAllIsTopPlaylist = useCallback(
		async (playlistIds) => {
			const res = await api.post(
				`/admin/playlists/set_is_top_playlist`,
				{
					playlist_ids: playlistIds,
				}
			)
			reload()
		},
		[]
	)

	const removeTopPlaylist = useCallback(
		async (playlistId) => {
			const res = await api.put(
				`/admin/playlists/${playlistId}`,
				{
					playlist: { is_top_playlist: false },
				}
			)
			reload()
		},
		[]
	)

	const updateTopPlaylistsPosition = useCallback(
		async (playlistId, position) => {
			const res = await api.post(
				`/admin/playlists/${playlistId}/top_playlists_position/${position}`
			)
			reload()
		},
		[]
	)

	useEffect(() => {
		reload()
	}, [])

	return {
		isLoading,
		topPlaylists,
		addTopPlaylist,
		addAllIsTopPlaylist,
		removeTopPlaylist,
		updateTopPlaylistsPosition,
		reload,
	}
}

export default useTopPlaylists
