import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDailyAffirmations } from 'hooks'
import CarouselAddAudio from 'containers/audios/CarouselAddAudio'

const DailyAffirmationsAddAudio = ({ ...props }) => {
	const history = useHistory()

	const audioQuery = {
		'audios.class_type': 'daily_affirmation',
		'audios.is_selected_daily_affirmation': false,
	}

	const { addAllDailyAffirmations } = useDailyAffirmations()

	return (
		<CarouselAddAudio
			title="Daily Affirmations Carousel > Add Audios"
			audioQuery={audioQuery}
			addToCarousel={(selectedIds) =>
				addAllDailyAffirmations(selectedIds)
			}
			goBack={() =>
				history.push('/curated_lists/daily_affirmations')
			}
		/>
	)
}

export default DailyAffirmationsAddAudio
