import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useVibes, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import VibeForm from 'containers/vibes/VibeForm'

const VibeNew = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		vibeId,
		isLoading,
		isLoaded,
		vibe,
		handleChange,
		createVibe,
		findVibe,
	} = useVibes({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		let newVibe = await createVibe(vibe)
		showAlertSuccess('Vibe has been created')
		history.push(`/vibes/${newVibe.id}/edit`)
	}

	useEffect(() => {
		if (id) findVibe(id)
	}, [])

	return (
		<>
			<Subheader
				title="Add Vibes"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<VibeForm
						vibe={vibe}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save and Continue
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default VibeNew
