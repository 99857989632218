import React, { useEffect, useState } from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useHistory } from 'react-router'

const useRatings = ({ ...props }) => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const history = useHistory()

	const [isLoaded, setIsLoaded] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props.id)
	const [rating, setRating] = useState({ id: props.id })
	const [ratings, setRatings] = useState([])
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [page, setPage] = useState(1)
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [errors, setErrors] = useState([])
	const [sortKey, setSortKey] = useState(`ratings.id`)
	const [sortDirection, setSortDirection] = useState('desc')

	const findRating = async (id) => {
		if (!id) showAlertError('No ID was provided')
		try {
			showLoading()
			setIsLoaded(false)
			setId(id)
			const res = await api.get(`/admin/ratings/${id}`)
			setRating(res.data)
			setMeta(res.meta)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const findRatings = async (params, page = 1) => {
		try {
			showLoading()
			setParams(params)
			setPage(page)
			setIsLoaded(false)
			const res = await api.get('/admin/ratings', {
				params: {
					...params,
					page: page,
					per_page: perPage,
				},
			})
			setRatings(res.data)
			if (res.meta) {
				setMeta(res.meta)
				setPage(res.meta.page)
				setNumPages(res.meta.num_pages)
				setTotalCount(res.meta.total_count)
			}
			setIsEmpty(res.data.length > 0 ? false : true)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const createRating = async (rating) => {
		try {
			showLoading()
			const res = await api.post(`/admin/ratings`, {
				rating,
			})
			if (res.data && res.data.id) {
				setRating(res.data)
				setIsLoaded(true)
				setId(res.data.id)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updateRating = async (rating) => {
		setId(rating.id)
		try {
			showLoading()
			const res = await api.put(
				`/admin/ratings/${rating.id}`,
				{
					rating,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteRating = async (rating) => {
		try {
			showLoading()
			const res = await api.delete(
				`/admin/ratings/${rating.id}`
			)
			setRating({ data: {} })
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const paginate = (page) => findRatings(params, page)

	const uploadFile = async (file, attributeName) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(`rating[${attributeName}]`, file)
			const res = await api.post(
				`/admin/ratings/${id}/upload_file`,
				formData,
				config
			)
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteFile = async (type) => {
		showLoading()
		await api.post(`/admin/ratings/${id}/delete_file`, {
			type,
		})
		hideLoading()
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setRating({
			...rating,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('useRating Error:', e)
	}

	const reloadRating = () => findRating(id)
	const reloadRatings = () => findRatings(params)

	const handleSort = (sortBy) => {
		sortDirection == 'asc'
			? setSortDirection('desc')
			: setSortDirection('asc')
		setSortKey(sortBy)
		history.push(
			`?sort_key=${sortKey}&sort_direction=${sortDirection}`
		)
	}

	useEffect(() => {
		if (props.id) setId(props.id)
	}, [props.id])

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		rating,
		ratings,
		errors,
		meta,
		findRating,
		findRatings,
		reloadRating,
		reloadRatings,
		updateRating,
		createRating,
		deleteRating,
		paginate,
		handleChange,
		uploadFile,
		deleteFile,
		params,
		sortKey,
		sortDirection,
		handleSort,
		page,
		numPages,
		perPage,
		totalCount,
	}
}

export default useRatings
