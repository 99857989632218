import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import {
	useAlerts,
	useAudios,
	useNavigation,
	useSelected,
} from 'hooks'
import { Subheader } from 'components'
import AudioTable from 'containers/audios/AudioTable'
import AudioToolbar from 'containers/audios/AudioToolbar'
import PlaylistModal from 'containers/playlists/PlaylistModal'

const AudioList = ({ ...props }) => {
	const history = useHistory()

	const [showPlaylistModal, setShowPlaylistModal] =
		useState(false)
	const [playlistId, setPlaylistId] = useState()

	const togglePlaylistModal = () =>
		setShowPlaylistModal(!showPlaylistModal)

	const { handleAddClick } = useNavigation({
		history,
		url: '/sessions',
	})

	const { showAlertSuccess } = useAlerts()

	const audioState = useAudios({})
	const {
		reloadAudios,
		bulkPublish,
		bulkUnpublish,
		addToPlaylist,
	} = audioState

	const selectedItemState = useSelected()
	const {
		selected,
		selectedIds,
		handleClear,
		totalSelected,
	} = selectedItemState

	const handlePublish = async () => {
		await bulkPublish(selectedIds)
		handleClear()
		showAlertSuccess('Published audios')
		reloadAudios()
	}

	const handleUnpublish = async () => {
		await bulkUnpublish(selectedIds)
		handleClear()
		showAlertSuccess('Un-published audios')
		reloadAudios()
	}

	const handleAddToPlaylist = async (playlist) => {
		await addToPlaylist(playlist?.id, selectedIds)
		setShowPlaylistModal(false)
		handleClear()
		showAlertSuccess(
			'Added all PUBLISHED audios to playlist'
		)
		reloadAudios()
	}

	return (
		<>
			<Subheader
				title="Sessions"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Session
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				<AudioTable
					audioState={audioState}
					selectedItemState={selectedItemState}
					renderToolbar={() => (
						<AudioToolbar
							selected={selected}
							selectedIds={selectedIds}
							handleClear={handleClear}
							totalSelected={totalSelected}
							handlePublish={handlePublish}
							handleUnpublish={handleUnpublish}
							togglePlaylistModal={togglePlaylistModal}
							cancelAction={handleClear}
						/>
					)}
					renderModal={() => (
						<PlaylistModal
							open={showPlaylistModal}
							handleClose={togglePlaylistModal}
							handleClick={handleAddToPlaylist}
							handleChange={(ev) =>
								setPlaylistId(ev.target.value)
							}
							playlistId={playlistId}
						/>
					)}
				/>
			</Container>
		</>
	)
}

export default AudioList
