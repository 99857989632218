import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAudios, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
	Grid,
} from '@material-ui/core'
import {
	CarouselImage,
	FileUploader,
	FullScreenImage,
	ImageUploader,
	SquareImage,
	Subheader,
} from 'components'
import { Check } from '@material-ui/icons'
import AudioForm from 'containers/audios/AudioForm'
import HeroImage from 'components/images/HeroImage'
import InstagramImage from 'components/images/InstagramImage'

const AudioEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		audioId,
		isLoading,
		isLoaded,
		audio,
		handleChange,
		updateAudio,
		findAudio,
		uploadFile,
		deleteFile,
	} = useAudios({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateAudio(audio)
		showAlertSuccess('Audio has been updated')
		history.push(`/sessions/${audio.id}`)
	}

	const handleUploadMedia = async (file) => {
		await uploadFile(file, 'media_url')
		findAudio(id)
	}

	const handleDeleteMedia = async (file) => {
		await deleteFile('media_url')
		findAudio(id)
	}

	const handleUploadCarouselImage = async (file) => {
		await uploadFile(file, 'carousel_image_url')
		findAudio(id)
	}

	const handleDeleteCarouselImage = async (file) => {
		await deleteFile('carousel_image_url')
		findAudio(id)
	}

	const handleUploadFullscreenImage = async (file) => {
		await uploadFile(file, 'fullscreen_image_url')
		findAudio(id)
	}

	const handleDeleteFullscreenImage = async (file) => {
		await deleteFile('fullscreen_image_url')
		findAudio(id)
	}

	useEffect(() => {
		if (id) findAudio(id)
	}, [])

	return (
		<>
			<Subheader
				title="Edit Audio"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<Grid container spacing={1}>
							<Grid item xs={12} lg={3}>
								<Box p={4}>
									<center>
										<Typography variant="body2">
											Fullscreen Img
										</Typography>
											<ImageUploader
												width={194}
												height={420}
												override={true}
												imageUrl={
													audio.fullscreen_image_url
												}
												demoImage={() => (
													<FullScreenImage
														src={audio.fullscreen_image_url}
														height={420}
													/>
												)}
												handleUpload={
													handleUploadFullscreenImage
												}
												handleDelete={
													handleDeleteFullscreenImage
												}
											/>
									</center>
								</Box>
							</Grid>
							<Grid item xs={12} lg={7}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={8}>
										<Box p={3}>
											<center>
												<Typography variant="body2">
													Hero Img
												</Typography>
												<HeroImage
													src={audio.hero_image_url ?? ''}
													size={160}
												/>
											</center>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box p={3}>
											<center>
												<Typography variant="body2">
													Instagram Img
												</Typography>
												<InstagramImage
													src={
														audio.instagram_image_url ?? ''
													}
													height={160}
												/>
											</center>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box p={3}>
											<center>
												<Typography variant="body2">
													Vibe Img (Sq.)
												</Typography>
												<SquareImage
													src={audio.vibe_image_url ?? ''}
													variant="rounded"
													size={160}
												/>
											</center>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box p={3}>
											<center>
												<Typography variant="body2">
													Trainer Img (Sq.)
												</Typography>
												<SquareImage
													src={
														audio.trainer_avatar_image_url ??
														''
													}
													variant="rounded"
													size={160}
												/>
											</center>
										</Box>
									</Grid>
									<Grid item xs={12} md={4}>
										<Box p={3}>
											<center>
												<Typography variant="body2">
													Trainer Vibe Img (Sq.)
												</Typography>
												<SquareImage
													src={
														audio.trainer_vibe_image_url ??
														''
													}
													variant="rounded"
													size={160}
												/>
											</center>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<AudioForm
							audio={audio}
							handleChange={handleChange}
						/>

						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default AudioEdit
