import React, { useEffect } from 'react'
import {
	usePlaylists,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	Layout,
	SortableTableHeader,
	Column,
	Subheader,
	Page,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Tooltip,
	Toolbar,
} from '@material-ui/core'
import {
	Add,
	Search as SearchIcon,
} from '@material-ui/icons'
import { sortBy } from 'lodash'
import PlaylistItem from 'containers/playlists/PlaylistItem'
import PlaylistToolbar from 'containers/playlists/PlaylistToolbar'
import PlaylistTable from './PlaylistTable'

const PlaylistList = ({ ...props }) => {
	const history = useHistory()

	const playlistState = usePlaylists({})
	const {
		findPlaylists,
		deletePlaylist,
		query,
		page,
		reloadPlaylists,
		sortKey,
		sortDirection,
	} = playlistState

	const { handleAddClick } = useNavigation({
		history,
		url: '/playlists',
	})

	const selectedItemState = useSelected()
	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	} = selectedItemState

	useEffect(() => {
		findPlaylists(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
			},
			page
		)
	}, [query, page, sortKey, sortDirection])

	return (
		<>
			<Subheader
				title="Playlists"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Playlist
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				<PlaylistTable
					playlistState={playlistState}
					isSelectable={false}
					selectedItemState={selectedItemState}
				/>
			</Container>
		</>
	)
}

export default PlaylistList
