import React from 'react'
import { useHistory } from 'react-router-dom'
import {
	Button,
	ListItem,
	ListItemText,
	ListItemIcon,
	Box,
} from '@material-ui/core'
import { PlaylistThumbnailImage } from 'components'

const PlaylistListItem = ({
	playlist,
	handleRemoveClick,
	handleMoveUp,
	handleMoveDown,
	...props
}) => {
	const history = useHistory()
	const handleClick = () =>
		history.push(`/playlists/${playlist.id}`)

	return (
		<ListItem>
			<ListItemIcon>
				<Box pr={2}>
					<Button onClick={handleClick}>
						<PlaylistThumbnailImage
							src={playlist.thumbnail_image_url}
							width={60}
						/>
					</Button>
				</Box>
			</ListItemIcon>
			<ListItemText primary={playlist.name} />
		</ListItem>
	)
}

export default PlaylistListItem
