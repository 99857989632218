import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { usePlaylists, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
	Grid,
} from '@material-ui/core'
import {
	FullScreenImage,
	ImageUploader,
	PlaylistThumbnailImage,
	Subheader,
} from 'components'
import { Check } from '@material-ui/icons'
import PlaylistForm from 'containers/playlists/PlaylistForm'

const PlaylistEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		playlistId,
		isLoading,
		isLoaded,
		playlist,
		handleChange,
		updatePlaylist,
		findPlaylist,
		uploadFile,
		deleteFile,
	} = usePlaylists({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		// if (!playlist.name) {
		// 	showAlertError('No Name was provided')
		// 	return
		// }
		await updatePlaylist(playlist)
		showAlertSuccess('Playlist has been updated')
		history.push('/playlists')
	}

	const handleUploadFullScreenImage = async (file) => {
		await uploadFile(file, 'fullscreen_image_url')
		findPlaylist(id)
	}

	const handleDeleteFullScreenImage = async (file) => {
		await deleteFile('fullscreen_image_url')
		findPlaylist(id)
	}

	const handleUploadThumbnailImage = async (file) => {
		await uploadFile(file, 'thumbnail_image_url')
		findPlaylist(id)
	}

	const handleDeleteThumbnailImage = async (file) => {
		await deleteFile('thumbnail_image_url')
		findPlaylist(id)
	}

	useEffect(() => {
		if (id) findPlaylist(id)
	}, [])

	const thumbnailImageUrl =
		playlist.thumbnail_image_url ===
		playlist.fullscreen_image_url
			? null
			: playlist.thumbnail_image_url

	return (
		<>
			<Subheader
				title="Edit Playlist"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center></center>

						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<Box p={1}>
									<center>
										<Typography variant="body2">
											Fullscreen Img
										</Typography>
										<ImageUploader
											width={166}
											height={360}
											imageUrl={
												playlist.fullscreen_image_url
											}
											demoImage={() => (
												<FullScreenImage
													src={
														playlist.fullscreen_image_url
													}
													height={420}
												/>
											)}
											handleUpload={
												handleUploadFullScreenImage
											}
											handleDelete={
												handleDeleteFullScreenImage
											}
										/>
									</center>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box p={1}>
									<center>
										<Typography variant="body2">
											Thumbnail Img
										</Typography>
										<ImageUploader
											width={191}
											height={240}
											imageUrl={thumbnailImageUrl}
											demoImage={() => (
												<PlaylistThumbnailImage
													src={thumbnailImageUrl}
													height={208}
												/>
											)}
											handleUpload={
												handleUploadThumbnailImage
											}
											handleDelete={
												handleDeleteThumbnailImage
											}
										/>
									</center>
								</Box>
							</Grid>
						</Grid>

						<PlaylistForm
							playlist={playlist}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default PlaylistEdit
