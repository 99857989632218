import React from 'react'
import {
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { SwitchInput, TextInput } from 'components'

const useStyles = makeStyles((theme) => ({
	linkDescription: {
		marginTop: 6,
	},
}))

const DiscoverOverrideForm = ({
	discoverOverride = {},
	isEditing,
	handleChange,
}) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={discoverOverride?.title}
					label="Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
				/>
				<TextInput
					value={discoverOverride?.subtitle}
					label="Subtitle"
					name="subtitle"
					placeholder="Subtitle"
					handleChange={handleChange}
				/>
				{/* TODO: audio */}
				<TextInput
					value={discoverOverride?.audio_id}
					label="Audio ID"
					name="audio_id"
					placeholder="Audio ID"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<SwitchInput
					value={
						discoverOverride?.published === true ? true : false
					}
					label="Published"
					name="published"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default DiscoverOverrideForm
