import React, { useEffect, useState } from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useHistory } from 'react-router'

const useDiscoverOverride = ({ ...props }) => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const history = useHistory()

	const [isLoaded, setIsLoaded] = useState(false)
	const [discoverOverride, setDiscoverOverride] = useState(null)
	const [errors, setErrors] = useState([])

	const findDiscoverOverride = async () => {
		try {
			showLoading()
			setIsLoaded(false)
			const res = await api.get(`/admin/discover_audio_override`)
			setDiscoverOverride(res.data)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const reloadDiscoverOverride = () => findDiscoverOverride()

	const createDiscoverOverride = async (discover_audio_override) => {
		try {
			showLoading()
			const res = await api.post(`/admin/discover_audio_override`, {
				discover_audio_override,
			})
			if (res.data) {
				setDiscoverOverride(res.data)
				setIsLoaded(true)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updateDiscoverOverride = async ({ title, subtitle, audio_id, published }) => {
		try {
			showLoading()
			const res = await api.put(`/admin/discover_audio_override`, {
				discover_audio_override: {
					title,
					subtitle,
					audio_id,
					published,
				},
			})
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteDiscoverOverride = async (discover_audio_override) => {
		try {
			showLoading()
			const res = await api.delete(`/admin/discover_audio_override`)
			setDiscoverOverride(null)
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const changePublished = async (value) => {
		try {
			const res = await api.put(
				`/admin/discover_audio_override`,
				{
					discover_audio_override: { published: !!value },
				}
			)
			reloadDiscoverOverride()
		} catch (e) {
			handleErrors(e)
		}
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value

		setDiscoverOverride((oldValue) => ({
			...oldValue,
			[name]: value,
		}))
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('useDiscoverOverride Error:', e)
	}

	useEffect(() => {
		findDiscoverOverride()
	}, [])

	return {
		isLoading,
		isLoaded,
		discoverOverride,
		errors,
		findDiscoverOverride,
		reloadDiscoverOverride,
		updateDiscoverOverride,
		createDiscoverOverride,
		deleteDiscoverOverride,
		changePublished,
		handleChange,
	}
}

export default useDiscoverOverride
