import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import {
	useAlerts,
	useNavigation,
	useTrainers,
	useVibes
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	SortableTableHeader,
	Placeholder,
	Search,
	Filter,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Checkbox,
	Box,
	Paper,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import AudioItem from 'containers/audios/AudioItem'
import {
	createAudioFilters,
	AUDIO_TABLE_HEADERS,
} from '../../lib/audioTableSettings'
import debounce from "lodash/debounce";

const AudioTable = ({
	audioQuery = {},
	audioState,
	selectedItemState,
	renderToolbar,
	renderModal,
	...props
}) => {
	const {
		isLoading,
		isLoaded,
		isEmpty,
		audios,
		findAudios,
		changeIsMuneTopPick,
		deleteAudio,
		paginate,
		query,
		reloadAudios,
		sortKey,
		sortDirection,
		handleSort,
		page,
		numPages,
	} = audioState

	const {
		vibes,
		findVibes,
		sortKey: vibeSortKey,
		query: vibeQuery,
		sortDirection: vibeSortDirection,
		page: vibePage,
	} = useVibes({})

	const {
		trainers,
		findTrainers,
		query: trainerQuery,
		page: trainerPage,
		sortKey: trainerSortKey,
		sortDirection: trainerSortDirection,
	} = useTrainers({})

	const [filters, setFilters] = useState({})
	const [filtersExpanded, setFiltersExpanded] =
		useState(false)

	const { selectedIds, handleSelect, handleSelectAll } =
		selectedItemState

	const history = useHistory()

	const { handleClick, handleEditClick } = useNavigation({
		history,
		url: '/sessions',
	})

	const { showAlertSuccess } = useAlerts()

	const handleDeleteClick = async (audio) => {
		await deleteAudio(audio)
		showAlertSuccess('Audio has been deleted')
		reloadAudios()
	}

	const handleExpand = (isExpanded) => {
		setFiltersExpanded(isExpanded)
	}

	const fetchAudios = useCallback(() => {
		const params = {
			...audioQuery,
			...query,
			...filters,
			sort_key: sortKey,
			sort_direction: sortDirection,
		}

		findAudios(params, page)
	}, [query, page, sortKey, sortDirection, filters])

	const onFilterChange = useCallback(
		(newFilter) => {
			setFilters((filters) => ({
				...filters,
				[newFilter.key]: newFilter.value,
			}))
		},
		[filters, setFilters]
	)

	useEffect(() => {
		fetchAudios()
	}, [])

	useEffect(() => {
		const debouncedFetchAudios = debounce(() => {
			if (Object.keys(filters).length !== 0) {
				fetchAudios()
			}
		}, 300) 
		debouncedFetchAudios()
		return () => {
			debouncedFetchAudios.cancel()
		}
	}, [filters])

	useEffect(() => {
		findVibes(
			{
				...vibeQuery,
				sort_key: vibeSortKey,
				sort_direction: vibeSortDirection,
			},
			vibePage
		)
	}, [vibeQuery, vibePage, vibeSortKey, vibeSortDirection])

	useEffect(() => {
		findTrainers(
			{
				...trainerQuery,
				sort_key: trainerSortKey,
				sort_direction: trainerSortDirection,
			},
			trainerPage
		)
	}, [
		trainerQuery,
		trainerPage,
		trainerSortKey,
		trainerSortDirection,
	])

	const isSelectable = true

	return (
		isLoaded && (
			<>
				<Paper>
					<Search
						isLoading={isLoading}
						query={query}
						handleSearch={findAudios}
					/>
					<Filter
						filters={createAudioFilters(vibes, trainers)}
						selectedFilters={filters}
						expanded={filtersExpanded}
						onExpandStateChange={handleExpand}
						numColumns={2}
						numCollapsedRows={2}
						onFilterChange={onFilterChange}
					/>
					{!isEmpty ? (
						<TableContainer component={Paper} elevation={0}>
							<Table>
								<TableHead>
									<TableRow>
										{isSelectable && (
											<TableCell>
												<Checkbox
													checked={
														selectedIds?.length ===
														audios?.length
													}
													onChange={() =>
														handleSelectAll(audios)
													}
													color="primary"
												/>
											</TableCell>
										)}
										{AUDIO_TABLE_HEADERS.filter(
											(h) => !h.hidden
										).map((header, idx) => (
											<SortableTableHeader
												key={idx}
												handleSort={handleSort}
												value={`audios.${header.value}`}
												sortable={header.sortable}
												sortKey={sortKey}
												sortDirection={sortDirection}
											>
												{header.label}
											</SortableTableHeader>
										))}
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{audios.map((audio, idx) => (
										<AudioItem
											key={idx}
											audio={audio}
											isSelectable={isSelectable}
											selectedIds={selectedIds}
											handleSelect={handleSelect}
											handleClick={handleClick}
											handleDeleteClick={handleDeleteClick}
											handleEditClick={handleEditClick}
											changeIsMuneTopPick={
												changeIsMuneTopPick
											}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Placeholder
							icon={<SearchIcon />}
							title="No audios"
							subtitle="Please try another search"
						/>
					)}
				</Paper>
				{!isEmpty && (
					<Box my={1}>
						<Pagination
							onChange={(ev, page) => paginate(page)}
							color="primary"
							size="small"
							shape="rounded"
							page={page}
							count={numPages}
						/>
					</Box>
				)}
				{renderToolbar ? renderToolbar() : null}
				{renderModal ? renderModal() : null}
			</>
		)
	)
}

export default AudioTable
