import React, { useState, useEffect } from 'react'

const useSelected = (props) => {
	const [selected, setSelected] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [totalSelected, setTotalSelected] = useState(0)

	const handleSelect = (item) => {
		if (selectedIds.find((id) => id === item.id)) {
			setSelected(selected.filter((i) => i.id != item.id))
			setSelectedIds(
				selectedIds.filter((id) => id != item.id)
			)
		} else {
			setSelected(selected.concat(item))
			setSelectedIds(selectedIds.concat(item.id))
		}
	}

	const handleClear = () => {
		setSelected([])
		setSelectedIds([])
	}

	const handleSelectAll = (items) => {
		if (selectedIds?.length < items?.length) {
			setSelected(items)
			setSelectedIds(items.map((item) => item?.id))
		} else {
			setSelected([])
			setSelectedIds([])
		}
	}

	useEffect(() => {
		setTotalSelected(selectedIds?.length)
	}, [selectedIds])

	return {
		selected,
		selectedIds,
		setSelected,
		setSelectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	}
}

export default useSelected
