import React from 'react'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { AuthRoute } from 'components'

//auth
import Login from 'containers/auth/Login'
import ForgotPassword from 'containers/auth/ForgotPassword'
import ResetPassword from 'containers/auth/ResetPassword'
import VerifyPin from 'containers/auth/VerifyPin'

//routes
import UserNew from 'containers/users/UserNew'
import UserShow from 'containers/users/UserShow'
import UserEdit from 'containers/users/UserEdit'
import UserList from 'containers/users/UserList'
import TrainerNew from 'containers/trainers/TrainerNew'
import TrainerShow from 'containers/trainers/TrainerShow'
import TrainerEdit from 'containers/trainers/TrainerEdit'
import TrainerList from 'containers/trainers/TrainerList'
import CuratedListList from 'containers/curatedLists/CuratedListList'
import DailyAffirmationsShow from 'containers/curatedLists/DailyAffirmationsShow'
import DailyAffirmationsAddAudio from 'containers/curatedLists/DailyAffirmationsAddAudio'
import TopPlaylistsShow from 'containers/curatedLists/TopPlaylistsShow'
import TopPlaylistsAddPlaylist from 'containers/curatedLists/TopPlaylistsAddPlaylist'
import MuneTopPicksShow from 'containers/curatedLists/MuneTopPicksShow'
import MuneTopPicksAddAudio from 'containers/curatedLists/MuneTopPicksAddAudio'
import MarketingTileNew from 'containers/marketingTile/MarketingTileNew'
import MarketingTileShow from 'containers/marketingTile/MarketingTileShow'
import MarketingTileEdit from 'containers/marketingTile/MarketingTileEdit'
import DiscoverOverrideNew from 'containers/discoverOverride/DiscoverOverrideNew'
import DiscoverOverrideShow from 'containers/discoverOverride/DiscoverOverrideShow'
import DiscoverOverrideEdit from 'containers/discoverOverride/DiscoverOverrideEdit'
import PlaylistNew from 'containers/playlists/PlaylistNew'
import PlaylistShow from 'containers/playlists/PlaylistShow'
import PlaylistEdit from 'containers/playlists/PlaylistEdit'
import PlaylistList from 'containers/playlists/PlaylistList'
import FavoriteList from 'containers/favorites/FavoriteList'
import RatingList from 'containers/ratings/RatingList'
import AudioNew from 'containers/audios/AudioNew'
import AudioShow from 'containers/audios/AudioShow'
import AudioEdit from 'containers/audios/AudioEdit'
import AudioList from 'containers/audios/AudioList'
import VibeNew from 'containers/vibes/VibeNew'
import VibeEdit from 'containers/vibes/VibeEdit'
import VibeList from 'containers/vibes/VibeList'
import VibeShow from 'containers/vibes/VibeShow'

const Routes = () => (
	<Switch>
		<Route path="/" component={Login} exact />
		<Route path="/login/:token" component={Login} />
		<Route path="/login" component={Login} />
		<Route
			path="/forgot_password"
			component={ForgotPassword}
			exact
		/>
		<Route path="/verify_pin" component={VerifyPin} exact />
		<Route
			path="/reset_password/:pin"
			component={ResetPassword}
			exact
		/>
		<Route
			path="/users/passwords/edit"
			component={ResetPassword}
			exact
		/>
		<AuthRoute
			path="/sessions/new"
			component={AudioNew}
			exact
		/>
		<AuthRoute
			path="/sessions/:id/edit"
			component={AudioEdit}
			exact
		/>
		<AuthRoute
			path="/sessions"
			component={AudioList}
			exact
		/>
		<AuthRoute
			path="/sessions/:id"
			component={AudioShow}
			exact
		/>
		<AuthRoute
			path="/rating"
			component={RatingList}
			exact
		/>
		<AuthRoute
			path="/favorites"
			component={FavoriteList}
			exact
		/>
		<AuthRoute
			path="/curated_lists"
			component={CuratedListList}
			exact
		/>
		<AuthRoute
			path="/curated_lists/daily_affirmations"
			component={DailyAffirmationsShow}
			exact
		/>
		<AuthRoute
			path="/curated_lists/daily_affirmations/new"
			component={DailyAffirmationsAddAudio}
			exact
		/>
		<AuthRoute
			path="/curated_lists/top_playlists"
			component={TopPlaylistsShow}
			exact
		/>
		<AuthRoute
			path="/curated_lists/top_playlists/new"
			component={TopPlaylistsAddPlaylist}
			exact
		/>
		<AuthRoute
			path="/curated_lists/mune_top_picks"
			component={MuneTopPicksShow}
			exact
		/>
		<AuthRoute
			path="/curated_lists/mune_top_picks/new"
			component={MuneTopPicksAddAudio}
			exact
		/>
		<AuthRoute
			path="/marketing_tile"
			component={MarketingTileShow}
			exact
		/>
		<AuthRoute
			path="/marketing_tile/new"
			component={MarketingTileNew}
			exact
		/>
		<AuthRoute
			path="/marketing_tile/edit"
			component={MarketingTileEdit}
			exact
		/>
		<AuthRoute
			path="/discover_override"
			component={DiscoverOverrideShow}
			exact
		/>
		<AuthRoute
			path="/discover_override/new"
			component={DiscoverOverrideNew}
			exact
		/>
		<AuthRoute
			path="/discover_override/edit"
			component={DiscoverOverrideEdit}
			exact
		/>
		<AuthRoute
			path="/playlists/new"
			component={PlaylistNew}
			exact
		/>
		<AuthRoute
			path="/playlists/:id/edit"
			component={PlaylistEdit}
			exact
		/>
		<AuthRoute
			path="/playlists"
			component={PlaylistList}
			exact
		/>
		<AuthRoute
			path="/playlists/:id"
			component={PlaylistShow}
			exact
		/>
		<AuthRoute
			path="/trainers/new"
			component={TrainerNew}
			exact
		/>
		<AuthRoute
			path="/trainers/:id/edit"
			component={TrainerEdit}
			exact
		/>
		<AuthRoute
			path="/trainers"
			component={TrainerList}
			exact
		/>
		<AuthRoute
			path="/trainers/:id"
			component={TrainerShow}
			exact
		/>
		<AuthRoute
			path="/vibes/new"
			component={VibeNew}
			exact
		/>
		<AuthRoute
			path="/vibes/:id/edit"
			component={VibeEdit}
			exact
		/>
		<AuthRoute path="/vibes" component={VibeList} exact />
		<AuthRoute
			path="/vibes/:id"
			component={VibeShow}
			exact
		/>
		<AuthRoute
			path="/users/new"
			component={UserNew}
			exact
		/>
		<AuthRoute
			path="/users/:id/edit"
			component={UserEdit}
			exact
		/>
		<AuthRoute path="/users" component={UserList} exact />
		<AuthRoute
			path="/users/:id"
			component={UserShow}
			exact
		/>
	</Switch>
)

export default Routes
