import React from 'react'
import { useMenu } from 'hooks'
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Image } from 'components'
import { MoreHoriz, Person } from '@material-ui/icons'

const FavoriteItem = ({
	favorite,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) => handleDeleteClick(favorite, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const history = useHistory()
	const handleUserClick = (user) =>
		history.push(`/users/${user.id}`)
	const handleAudioClick = (audio) =>
		history.push(`/sessions/${audio.id}`)

	const isSelected = selectedIds.includes(favorite.id)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(favorite)}
						name="favorite"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button
					onClick={() => handleUserClick(favorite.user)}
				>
					{favorite?.user?.name}
				</Button>
			</TableCell>
			<TableCell>
				<Button
					onClick={() => handleAudioClick(favorite.audio)}
				>
					{favorite?.audio?.name}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default FavoriteItem
