import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUsers, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import UserForm from 'containers/users/UserForm'

const UserNew = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		userId,
		isLoading,
		isLoaded,
		user,
		handleChange,
		createUser,
		findUser,
	} = useUsers({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await createUser(user)
		showAlertSuccess('User has been created')
		history.push('/users')
	}

	useEffect(() => {
		if (id) findUser(id)
	}, [])

	return (
		<>
			<Subheader
				title="Add Users"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<UserForm
						user={user}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default UserNew
