import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useVibes, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import {
	ImageUploader,
	FileUploader,
	Subheader,
} from 'components'
import { Check } from '@material-ui/icons'
import VibeForm from 'containers/vibes/VibeForm'

const VibeEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		vibeId,
		isLoading,
		isLoaded,
		vibe,
		handleChange,
		updateVibe,
		findVibe,
		uploadFile,
		deleteFile,
	} = useVibes({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateVibe(vibe)
		showAlertSuccess('Vibe has been updated')
		history.push('/vibes')
	}

	const handleUploadImage = async (file) => {
		await uploadFile(file, 'intro_image_url')
		findVibe(id)
	}

	const handleUploadMedia = async (file) => {
		await uploadFile(file, 'intro_media_url')
		findVibe(id)
	}

	const handleDeleteImage = async (file) => {
		await deleteFile('intro_image_url')
		findVibe(id)
	}

	const handleDeleteMedia = async (file) => {
		await deleteFile('intro_media_url')
		findVibe(id)
	}

	useEffect(() => {
		if (id) findVibe(id)
	}, [])

	return (
		<>
			<Subheader
				title="Edit Vibe"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<Typography variant="body2">
							Vibe Image
						</Typography>
						<center>
							<ImageUploader
								imageUrl={vibe.intro_image_url}
								handleUpload={handleUploadImage}
								handleDelete={handleDeleteImage}
							/>
						</center>
						<Typography variant="body2">
							Vibe Audio
						</Typography>
						<center>
							<FileUploader
								fileUrl={vibe.intro_media_url}
								handleUpload={handleUploadMedia}
								handleDelete={handleDeleteMedia}
							/>
						</center>
						<VibeForm
							vibe={vibe}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default VibeEdit
