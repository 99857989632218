import React, { useEffect, useState } from 'react'
import api from 'api'
import AsyncSelect from 'react-select/lib/Async'
import { Typography } from '@material-ui/core'
import { buildOptions, syntheticEvent } from 'lib/utils'
import { selectStyles } from 'components/styles'
import styled from 'styled-components'

const StyledContainer = styled('div')`
	${(props) => props.height && `height: ${props.height}px;`}
`

const AsyncAutosuggest = ({
	value,
	label,
	name,
	remoteUrl,
	query,
	displayField,
	handleChange,
	position,
	placeholder = 'Search...',
	...props
}) => {
	const [option, setOption] = useState()
	const [options, setOptions] = useState([])
	const [height, setHeight] = useState()
	const validateFn = () => ({ isValid: true })

	useEffect(() => {
		fetchDefaultOptions()
		if (value) {
			fetchDefaultValue(value)
		}
	}, [value])

	const fetchDefaultValue = async (value) => {
		let resp = await api.get(`${remoteUrl}/${value}`)
		console.log('FETCH DEFAULT VALUE')
		setOption({
			value: resp.data.id,
			label: resp.data[displayField],
		})
	}

	const fetchDefaultOptions = async () => {
		let resp = await api.get(remoteUrl, {
			params: {
				...query,
			},
		})
		let opts = buildOptions(resp.data, 'id', displayField)
		setOptions(opts)
	}

	const loadOptions = async (search) => {
		return api
			.get(remoteUrl, {
				params: {
					search,
					...query,
				},
			})
			.then((resp) => {
				let opts = buildOptions(
					resp.data,
					'id',
					displayField
				)
				return Promise.resolve(opts)
			})
			.catch((e) => {
				console.log(e)
				return Promise.reject(e)
			})
	}

	const handleExpand = () => {
		if (position === 'static') setHeight(340)
	}

	const handleShrink = () => setHeight()

	const handleInputChange = (selectedOption) => {
		setOption(selectedOption)
		let _value = selectedOption && selectedOption.value
		let ev = syntheticEvent(_value, name)
		handleChange(ev)
	}

	return (
		<StyledContainer height={height}>
			{label && (
				<Typography variant="body2">{label}</Typography>
			)}
			<AsyncSelect
				isClearable
				cacheOptions
				value={option}
				defaultOptions={options}
				loadOptions={loadOptions}
				placeholder={placeholder}
				styles={selectStyles}
				onMenuClose={handleShrink}
				onMenuOpen={handleExpand}
				onChange={handleInputChange}
			/>
		</StyledContainer>
	)
}

export default AsyncAutosuggest
