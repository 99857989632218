import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTopPlaylists } from 'hooks'
import CarouselAddPlaylist from 'containers/playlists/CarouselAddPlaylist'

const TopPlaylistsAddPlaylist = ({ ...props }) => {
	const history = useHistory()

	const playlistQuery = {
		'playlists.is_top_playlist': false,
	}

	const { addAllIsTopPlaylist } = useTopPlaylists()

	return (
		<CarouselAddPlaylist
			title="Top Playlists Carousel > Add Playlists"
			playlistQuery={playlistQuery}
			addToCarousel={(selectedIds) =>
				addAllIsTopPlaylist(selectedIds)
			}
			goBack={() =>
				history.push('/curated_lists/top_playlists')
			}
		/>
	)
}

export default TopPlaylistsAddPlaylist
