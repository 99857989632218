import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useTrainers, useAudios, useAlerts } from 'hooks'
import {
	Box,
	List,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Subheader } from 'components'
import TrainerDetails from 'containers/trainers/TrainerDetails'
import AudioListItem from 'containers/audios/AudioListItem'

const TrainerShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		trainerId,
		isLoading,
		isLoaded,
		trainer,
		findTrainer,
		changeFeatured,
	} = useTrainers({
		id: id,
	})

	const { removeTrainer, moveAudioUp, moveAudioDown } =
		useAudios()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		history.push(`/trainers/${id}/edit`)

	const handleRemoveClick = async (audio) => {
		await removeTrainer(audio?.id)
		findTrainer(id)
	}

	const handleMoveUp = async (audio) => {
		await moveAudioUp(audio?.id)
		findTrainer(id)
	}

	const handleMoveDown = async (audio) => {
		await moveAudioDown(audio?.id)
		findTrainer(id)
	}

	const toggleFeatured = (ev) => changeFeatured(trainer, !trainer.featured)

	useEffect(() => {
		if (id) findTrainer(id)
	}, [])

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${trainer.name}`}
				buttons={[
					<Button
						variant="contained"
						onClick={handleEditClick}
						color="primary"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<TrainerDetails trainer={trainer} toggleFeatured={toggleFeatured} />
					</Box>
				</Paper>
				<Box my={4} />
				{trainer.audios && trainer.audios.length > 0 ? (
					<Paper>
						<List>
							{trainer.audios.map((audio, idx) => (
								<AudioListItem
									position={idx + 1}
									handleMoveUp={handleMoveUp}
									handleMoveDown={handleMoveDown}
									handleRemoveClick={handleRemoveClick}
									key={audio.id}
									audio={audio}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default TrainerShow
