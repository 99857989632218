import React from 'react'
import { useMenu } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	Hidden,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Avatar,
	Menu,
	MenuItem,
	Box,
	ListItemSecondaryAction,
} from '@material-ui/core'
import { SquareImage } from 'components'
import {
	Close,
	MoreHoriz,
	ExpandLess,
	ExpandMore,
} from '@material-ui/icons'

const PlaylistAudioItem = ({
	audio,
	position,
	handleMoveUp,
	handleMoveDown,
	handleRemoveClick,
	...props
}) => {
	const history = useHistory()
	const handleClick = () =>
		history.push(`/sessions/${audio.id}`)

	const imageUrl =
		audio.class_type === 'on_demand'
			? audio.trainer_avatar_image_url
			: audio.class_type === 'daily_class'
			? audio.vibe_image_url
			: audio.fullscreen_image_url

	return (
		<ListItem>
			<ListItemIcon>
				<Box pr={2}>
					<Button onClick={handleClick}>
						<SquareImage src={imageUrl} size={80} />
					</Button>
				</Box>
			</ListItemIcon>
			<ListItemText
				primary={`${position}. ${audio.name}`}
				secondary={audio.category}
			/>
			<ListItemSecondaryAction>
				<IconButton onClick={() => handleMoveDown(audio)}>
					<ExpandMore />
				</IconButton>
				<IconButton onClick={() => handleMoveUp(audio)}>
					<ExpandLess />
				</IconButton>
				{handleRemoveClick && (
					<IconButton
						onClick={() => handleRemoveClick(audio)}
					>
						<Close />
					</IconButton>
				)}
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default PlaylistAudioItem
