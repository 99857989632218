import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	useAudios,
	useForm,
	useAlerts,
	useMarketingTile,
} from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
	Grid,
} from '@material-ui/core'
import {
	CarouselImage,
	FileUploader,
	FullScreenImage,
	ImageUploader,
	SquareImage,
	Subheader,
} from 'components'
import { Check } from '@material-ui/icons'
import MarketingTileForm from './MarketingTileForm'
import MarketingTileImageEditor from './MarketingTileImageEditor'

const MarketingTileEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		isLoading,
		isLoaded,
		marketingTile = {},
		handleChange,
		updateMarketingTile,
		reloadMarketingTile,
		uploadFile,
		deleteFile,
	} = useMarketingTile()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateMarketingTile(marketingTile)
		showAlertSuccess('Marketing tile has been updated')
		history.push(`/marketing_tile`)
	}

	return (
		<>
			<Subheader title="Edit Promo Tile" />
			<Container>
				<Paper>
					<Box p={4}>
						<MarketingTileImageEditor
							{...{
								marketingTile,
								uploadFile,
								deleteFile,
								reloadMarketingTile,
							}}
						/>

						<MarketingTileForm
							marketingTile={marketingTile}
							handleChange={handleChange}
						/>

						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default MarketingTileEdit
